import React, { useContext } from "react";

import PropTypes from "prop-types";

import "./facts.scss";

import { LangContext } from "../../App";
import OutlinedButton from "../ui/OutlinedButton";

export const Facts = ({
  fact,
  title,
  setRandomQuestion,
  setShowFact,
  setCurrentQuestion,
  showNewNumber,
}) => {
  const onNextHandler = () => {
    setRandomQuestion(null);
    setCurrentQuestion(null);
    setShowFact(false);
    showNewNumber();
  };
  const i18n = useContext(LangContext);
  return (
    <div className="facts">
      <div className="facts__title">
        <span className="facts__font facts__font--title font--text">
          {i18n.t(title) || i18n.t("common:interestingFact")}
        </span>
      </div>

      <div className="facts__text">
        <span className="facts__font facts__font--text font--text">
          {i18n.t(fact)}
        </span>
      </div>

      <OutlinedButton
        color="pink"
        className="facts__button"
        onClick={onNextHandler}
      >
        {i18n.t("common:next")}
      </OutlinedButton>
    </div>
  );
};

Facts.propTypes = {
  fact: PropTypes.string,
  title: PropTypes.string,
  setShowFact: PropTypes.func.isRequired,
  setRandomQuestion: PropTypes.func.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  showNewNumber: PropTypes.func.isRequired,
};
