import React from "react";

import { GameTypeSwitcher } from "../../components/game_type_switcher";
import useLocale from "../../hooks/useLocale";
import { useParams } from "react-router-dom";

export const StartPage = () => {
  const { locale } = useParams();

  useLocale(locale);
  return (
    <div>
      <GameTypeSwitcher />
    </div>
  );
};
