import React from "react";

import PropTypes from "prop-types";

export const CurrentGameContext = React.createContext({
  currentGame: {
    type: "single",
    players: [],
  },
});

export function CurrentGameProvider({ children }) {
  const defaultState = {
    type: "single",
    players: [],
  };
  const [currentGame, setCurrentGame] = React.useState(defaultState);
  const updateCurrentGame = React.useCallback(
    (data) => {
      setCurrentGame((game) => ({ ...game, ...data }));
    },
    [setCurrentGame]
  );

  return (
    <CurrentGameContext.Provider
      value={{
        currentGame,
        setCurrentGame,
        updateCurrentGame,
      }}
    >
      {children}
    </CurrentGameContext.Provider>
  );
}

CurrentGameProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
