import React from "react";
import classNames from "classnames";

import "./radio.scss";

function Radio({ className, children, name, value, onChange }) {
  return (
    <label className={classNames(className, "radio")}>
      <input
        className="radio__input"
        type="radio"
        name={name}
        value={value}
        onChange={() => onChange(value)}
      />

      <div className="radio__field" />

      <span className="radio__font font--text">{children}</span>
    </label>
  );
}

export default Radio;
