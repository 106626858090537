import React, { createElement } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import "./outlined-button.scss";

function OutlinedButton({
  className = "",
  color = "purple",
  size = "large",
  children,
  to = "",
  href = "",
  onClick,
  disabled = false,
}) {
  const tag = to ? Link : href ? 'a' : "button";

  return createElement(
    tag,
    {
      className: classNames(
        className,
        "outlined-button",
        `outlined-button--color--${color}`,
        `outlined-button--size--${size}`
      ),
      to: to || undefined,
      href: href || undefined,
      onClick,
      disabled,
    },
    <span className="outlined-button__font font--text">{children}</span>
  );
}

export default OutlinedButton;
