import React, { useContext } from "react";

import { Button, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { NotFoundBlock, Box } from "../../global_styles";
import useLocale from "../../hooks/useLocale";
import { LangContext } from "../../App";

export const Page404 = () => {
  const navigate = useNavigate();
  const i18n = useContext(LangContext);

  const { locale } = useParams();

  useLocale(locale);
  return (
    <NotFoundBlock>
      <Typography.Title style={{ fontSize: 80, textAlign: "center" }} level={1}>
        {i18n.t("common:notFound")}
      </Typography.Title>
      <Box p="10px 0">
        <Button
          onClick={() => navigate("/")}
          style={{ width: 250 }}
          ghost
          shape="round"
        >
          {i18n.t("common:goMain")}
        </Button>
      </Box>
    </NotFoundBlock>
  );
};
