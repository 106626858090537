import React from "react";

import { Modal } from "antd";
import PropTypes from "prop-types";

export const BasicModal = ({
  isModalOpen,
  title = "",
  children,
  handleCancel = () => {},
  footer = null,
  closable = false,
  style,
  width,
}) => {
  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        onCancel={handleCancel}
        closable={closable}
        footer={footer}
        style={style}
        width={width ? width : 520}
      >
        {children}
      </Modal>
    </>
  );
};

BasicModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleCancel: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
  footer: PropTypes.oneOf([PropTypes.bool, null]),
  closable: PropTypes.bool,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
