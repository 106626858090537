import React, { useContext, useEffect, useState } from "react";

import { Button, message, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import "./pages-main.scss";

import { CardsArea } from "../../components/cards_area";

import { Loader } from "../../components/loader";
import { BasicModal } from "../../components/modal/basic_modal";
import { PlayedNumbersHeader } from "../../components/played_numbers_header/played_numbers_header";
import { Question } from "../../components/questions_block/question";

import { VideoFrame } from "../../components/video_frame";
import { PULL } from "../../constants";
import {
  Box,
  TableContetnContainer,
  GamingTable,
  Coin,
  FlexCenteredContainer,
} from "../../global_styles";
import { CurrentGameContext } from "../../providers/current_game";
import q from "../../questions/questions.json";
import { generateMultiplayer } from "../../utils";
import useLocale from "../../hooks/useLocale";
import { LangContext } from "../../App";
import Default from "../../layout/Default";
import ThankYou from "../../components/common/ThankYou/ThankYou";

export const MainPage = () => {
  const { currentGame, updateCurrentGame } = useContext(CurrentGameContext);
  const navigate = useNavigate();

  const [actualNumbers, setActualNumbers] = useState([...PULL]);
  const [usedNumbers, setUsedNumbers] = useState([]);
  const [actualNumber, setActualNumber] = useState();

  const [users, setUsers] = useState([]);
  const [showCoin, setShowCoin] = useState(false);
  const [loading, setLoading] = useState(true);

  const questionsPull = q.questions;
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenWinnerModal, setIsOpenWinnerModal] = useState(false);
  const [loseModal, setLoseModal] = useState(false);

  const [toggle, setToggle] = useState(true);

  const [isShow, setIsShow] = useState(false);
  const isShowCoin = showCoin && actualNumber;
  const isSingle = currentGame?.wrongAnswers >= 3;

  const [isOpenRulesModal, setIsOpenRulesModal] = useState(false);

  const isIncludesError = users[0]?.card?.some((e) => e === "error");
  const loseText = isIncludesError
    ? `Гру завершено. Ви припустились ${
        currentGame.players[0]?.wrongAnswers
      } помил${currentGame?.wrongAnswers > 1 ? "ок" : "ки"}`
    : "На жаль, ви припустились 3 помилок. Гру завершено";

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const i18n = useContext(LangContext);
  const { locale } = useParams();

  useLocale(locale);

  const startNewGame = () => {
    const gameSettings = JSON.parse(localStorage.getItem("game"));

    if (!!gameSettings) {
      updateCurrentGame(gameSettings);
      setUsers(gameSettings.players);
      setTimeout(() => {
        setLoading(false);
        setIsOpenModal(true);
      }, 1500);
    } else {
      message.error(i18n.t("common:registerError"));
      setTimeout(() => {
        navigate(`/${i18n.language}`);
      }, 2500);
    }
  };

  const randomNumber = () => {
    const tempNumbers = JSON.parse(localStorage.getItem("numbers")) || PULL;
    const used = JSON.parse(localStorage.getItem("used")) || [];
    if (tempNumbers.length) {
      setShowCoin(true);
      const randomItem = Math.floor(Math.random() * tempNumbers.length);
      const currentNumber = tempNumbers[randomItem];
      setUsedNumbers((prev) => [currentNumber, ...prev]);
      setActualNumber(currentNumber);
      setActualNumbers((prev) => prev.filter((n) => n !== currentNumber));
      // saved data with localStorage
      localStorage.setItem(
        "numbers",
        JSON.stringify(tempNumbers.filter((n) => n !== currentNumber))
      );
      localStorage.setItem("used", JSON.stringify([currentNumber, ...used]));

      setTimeout(() => {
        checkNumber(currentNumber);
      }, 4000);
    } else {
      message.warning(i18n.t("common:numbersUsed"));
    }
  };

  const checkNumber = (number) => {
    let usersWithNumbers = [];

    if (currentGame.type === "single") {
      usersWithNumbers = users?.filter((user) => user?.card.includes(number));
    } else {
      usersWithNumbers = users?.filter(
        (user) => user?.card.includes(number) && user.status === "active"
      );
    }

    if (usersWithNumbers?.length) {
      handleAnswer(usersWithNumbers, number);
    } else {
      setShowCoin(false);
      showNewNumber();
    }
  };

  const handleAnswer = (users, number) => {
    const findQuestion = questionsPull.filter((q) => q.id === `qb${number}`);
    setCurrentQuestion({ users, q: findQuestion[0], number });
  };

  const checkWinner = () => {
    users?.forEach((user) => {
      if (user?.card?.every((e) => typeof e === "string")) {
        setIsOpenWinnerModal(true);
        setIsShow(false);
        setUsedNumbers([]);
        setActualNumbers([...PULL]);
        setActualNumber(null);
        setCurrentQuestion(null);
        return;
      }
    });
  };

  const checkLose = () => {
    if (
      (currentGame.type === "single" &&
        currentGame.players[0]?.wrongAnswers >= 3) ||
      (currentGame.type === "multiplayer" &&
        currentGame.players.every((player) => player.status === "inactive"))
    ) {
      setLoseModal(true);
      setIsShow(false);
      setUsedNumbers([]);
      setActualNumbers([...PULL]);
      setActualNumber(null);
      setCurrentQuestion(null);
      return;
    }
  };
  const generateLoseText = () => {
    if (
      currentGame.type === "single" &&
      currentGame.players[0]?.wrongAnswers >= 3
    ) {
      return i18n.t("common:attemptsOut");
    }

    if (
      currentGame.type === "multiplayer" &&
      currentGame.players.every((player) => player.status === "inactive")
    ) {
      return i18n.t("common:allAttemptsOut");
    }
  };

  const getWinnerName = () => {
    const idx = users.findIndex((user) =>
      user?.card?.every((e) => typeof e === "string")
    );
    return idx >= 0 ? users[idx].name : "empty";
  };

  const showNewNumber = () => {
    setShowCoin(false);

    isShow && checkWinner();
    checkLose();
    setTimeout(() => {
      randomNumber();
    }, 150);
  };

  const startGame = () => {
    localStorage.removeItem("numbers");
    localStorage.removeItem("used");
    setIsShow(true);
    showNewNumber();
    setIsOpenModal(false);
    setCurrentQuestion(null);
    setUsedNumbers([]);
    setActualNumbers([...PULL]);
    setActualNumber(null);
    setCurrentQuestion(null);
  };

  const restart = () => {
    const names = users.map((player) => player.name);
    const multiplayer = generateMultiplayer(names);
    currentGame.players = [...multiplayer];
    if (currentGame.type === "single") {
      currentGame.wrongAnswers = 0;
    }
    updateCurrentGame(currentGame);
    localStorage.setItem("game", JSON.stringify(currentGame));
    setLoseModal(false);
    setIsOpenWinnerModal(false);
    setTimeout(() => {
      startNewGame();
    }, 1000);
  };

  const finish = () => {
    localStorage.clear();
    navigate(`/${i18n.language}/choose-type`);
  };

  const findCategory = () => {
    const catName = questionsPull.filter((q) => q.id === `qb${actualNumber}`);
    return catName.length ? catName[0].category : "default";
  };

  const actionButtons = (
    <Box p="20px 0" style={{ display: "flex" }}>
      <FlexCenteredContainer>
        <Box m="2px">
          <Button
            onClick={finish}
            size="large"
            danger
            type="primary"
            shape="round"
          >
            {isSingle ? i18n.t("common:exit") : i18n.t("common:endGame")}
          </Button>
        </Box>
        <Box m="2px">
          <Button onClick={restart} ghost shape="round" size="large">
            {i18n.t("common:tryAgain")}
          </Button>
        </Box>
      </FlexCenteredContainer>
    </Box>
  );

  useEffect(() => {
    startNewGame();
  }, []);

  useEffect(() => {
    if (windowSize <= 760) {
      currentQuestion ? setToggle(true) : setToggle(false);
    } else {
      setToggle(false);
    }
  }, [currentQuestion, windowSize]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    setToggle(true);
    setTimeout(() => {
      setToggle(false);
    }, 1500);
  }, []);

  return (
    <Default className="pages-main">
      <div className="pages-main__container">
        {loading && <Loader />}
        {isOpenWinnerModal && <ThankYou className="pages-main__thanks" />}
        {!loading && !isOpenWinnerModal && (
          <div style={{ position: "relative" }}>
            <>
              {isShow && (
                <PlayedNumbersHeader
                  usedNumbers={usedNumbers}
                  isShow={isShow}
                />
              )}
              <GamingTable>
                {isShow && (
                  <div className="pages-main__question-wrapper">
                    {!!currentQuestion && (
                      <Question
                        question={currentQuestion}
                        setCurrentQuestion={setCurrentQuestion}
                        showNewNumber={showNewNumber}
                      />
                    )}
                  </div>
                )}
                {isShow && (
                  <TableContetnContainer
                    minWidth="150px"
                    minHeight={windowSize <= 760 ? "166px" : "350px"}
                    bgc="transparent"
                    margin={0}
                    padding={0}
                  >
                    <FlexCenteredContainer>
                      {isShowCoin && (
                        <Box>
                          <div className="pages-main__category">
                            <span className="pages-main__font pages-main__font--category font--text">
                              {i18n.t("common:category")}{" "}
                              {i18n.t(findCategory())}
                            </span>
                          </div>

                          <Coin
                            style={{ transition: "all 0.5s ease-out" }}
                            w={windowSize <= 760 ? "100px" : "160px"}
                            h={windowSize <= 760 ? "100px" : "160px"}
                            fs={windowSize <= 760 ? "66px" : "110px"}
                          >
                            <span className="pages-main__font pages-main__font--number font--title">
                              {actualNumber}
                            </span>
                          </Coin>
                        </Box>
                      )}
                    </FlexCenteredContainer>
                  </TableContetnContainer>
                )}
              </GamingTable>
            </>
            {isShow && (
              <CardsArea
                players={users}
                toggle={toggle}
                setToggle={setToggle}
                type={currentGame.type}
                actualNumber={actualNumber}
              />
            )}
          </div>
        )}
        <BasicModal
          isModalOpen={isOpenModal}
          title={i18n.t("common:gameStart")}
        >
          <Box style={{ height: 150 }}>
            <FlexCenteredContainer>
              <Button
                onClick={startGame}
                size="large"
                shape="round"
                block
                ghost
              >
                {i18n.t("common:start")}
              </Button>
            </FlexCenteredContainer>
          </Box>
        </BasicModal>

        <BasicModal isModalOpen={loseModal}>
          <Box style={{ minHeight: 150, textAlign: "center" }}>
            <Typography.Title level={3}>{generateLoseText()}</Typography.Title>
            {actionButtons}
          </Box>
        </BasicModal>

        <BasicModal
          isModalOpen={isOpenRulesModal}
          handleCancel={() => {
            setIsOpenRulesModal(false);
          }}
          closable={true}
          style={{ maxWidth: 850, minWidth: 280 }}
          width="100%"
        >
          <Box style={{ height: 500, textAlign: "center", marginTop: 35 }}>
            <VideoFrame />
          </Box>
        </BasicModal>
      </div>
    </Default>
  );
};
