import React from "react";

import { Spin } from "antd";

import { Box, FlexCenteredContainer } from "../../global_styles";

export const Loader = () => (
  <Box style={{ position: "fixed", top: "50%", left: " 50%" }}>
    <FlexCenteredContainer>
      <Spin size="large" />
    </FlexCenteredContainer>
  </Box>
);
