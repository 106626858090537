import React, { createElement, useContext, useState } from "react";
import classNames from "classnames";

import { CurrentGameContext } from "../../providers";
import { LangContext } from "../../App";

import "./pages-start.scss";

import Default from "../../layout/Default";
import { SinglePlayerTab, MultiplayerTab } from "../start_page_tabs";

export const GameTypeSwitcher = () => {
  const { currentGame, updateCurrentGame } = useContext(CurrentGameContext);
  const [tab, setTab] = useState("single");
  const i18n = useContext(LangContext);

  const Component = tab === "single" ? SinglePlayerTab : MultiplayerTab;
  const onChange = (key) => {
    console.log(key);
    setTab(key);
    currentGame.type = key;
    updateCurrentGame(currentGame);
  };

  return (
    <Default className="pages-start">
      <div className="pages-start__container">
        <div className="pages-start__body">
          <div className="pages-start__tabs">
            <button
              className="pages-start__tab"
              onClick={() => onChange("single")}
            >
              <span className="pages-start__font font--text">
                {i18n.t("common:singleGame")}
              </span>
            </button>

            <button
              className="pages-start__tab"
              onClick={() => onChange("multiplayer")}
            >
              <span className="pages-start__font font--text">
                {i18n.t("common:multiplayer")}
              </span>
            </button>
          </div>

          <div
            className={classNames("pages-start__progress", {
              "pages-start__progress--right": tab === "multiplayer",
            })}
          />

          {createElement(Component, { className: "pages-start__form" })}
        </div>
      </div>
    </Default>
  );
};
