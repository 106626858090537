const removedNumbers = [41];

export const PULL = [...Array(54).keys()].filter(
  (n) => n != 0 && !removedNumbers.includes(n)
);

export const DEBOUNCED_TIME = 3000;

export const DOWNLOAD_URL =
  "https://drive.google.com/drive/folders/1zDN3qPP5l1-lIaFSZLGV7jGqW0l6NzQQ?usp=drive_link";
