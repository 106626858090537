import styled, { keyframes } from "styled-components";

export const Box = styled.div`
  padding: ${(props) => props.p};
  margin: ${(props) => props.m};
  width: ${(props) => props.width};
`;
export const ContentContainer = styled.div`
  height: ${(props) => props.height};
  background: ${(props) => props.bgc};
  border-radius: 1rem;
  margin: 1rem;
  padding: 1rem;
  overflow-y: auto;
`;

export const TableContetnContainer = styled.div`
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  background: ${(props) => props.bgc || "#ffff"};
  border-radius: 1rem;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  margin-left: 20px;
`;

export const ActionsContainer = styled.div`
  margin: 0 1rem;
  display: flex;
  justify-content: center;
`;

export const GamingTable = styled.div`
  box-sizing: border-box;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  height: 100%;
  padding-bottom: 28px;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding-bottom: 11px;
  }
`;

export const FlexCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
`;

const spin = keyframes`
  0%{
    -webkit-transform: rotateY(2880deg);
    -moz--transform: rotateY(2880deg);
    -ms-transform: rotateY(2880deg);
    -o-transform: rotateY(2880deg);
    transform: rotateY(2880deg);
  }
  100% {
    -webkit-transform: rotateY(0);
    -moz--transform: rotateY(0);
    -ms-transform: rotateY(0);
    -o-transform: rotateY(0); 
    transform: rotateY(0);
  }
`;

export const Coin = styled.div`
  font-family: "MakBold" !important;
  width: ${(props) => props.w || "180px"};
  height: ${(props) => props.h || "180px"};
  border-radius: 50%;
  background-color: #9d9cf8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fs || "110px"};
  font-weight: bold;
  animation-name: ${spin};
  animation-duration: 2s;
  margin: 0 auto;
`;

export const NotFoundBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

export const VideoArea = styled.div`
  text-align: center;
  margin: 0 auto;
  background-color: #efd7be;
  border-radius: 16px;
  max-width: 691px;
  min-width: 280px;
  width: 100%;
  height: 100%;
  max-height: 405px;
  min-height: 300px;
  transition: ease-in 0.5s;
`;
