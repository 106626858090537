import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import "../tab-form.scss";

import { CurrentGameContext } from "../../../providers";
import { createUser } from "../../../utils";
import { LangContext } from "../../../App";
import OutlinedButton from "../../ui/OutlinedButton";
import classNames from "classnames";

export const SinglePlayerTab = ({ className }) => {
  const { currentGame, updateCurrentGame } = useContext(CurrentGameContext);
  const navigate = useNavigate();
  const [player, setPlayer] = useState();
  const i18n = useContext(LangContext);
  const inputHandler = (e) => {
    setPlayer((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const startSingleGame = () => {
    const singlePlayer = createUser(player.name);
    singlePlayer.wrongAnswers = 0;
    currentGame.players = [singlePlayer];
    updateCurrentGame(currentGame);
    localStorage.setItem("game", JSON.stringify(currentGame));
    navigate(`/${i18n.language}/game`);
  };
  return (
    <div className={classNames(className, "tab-form")}>
      <input
        className="tab-form__input font--text"
        name="name"
        placeholder={i18n.t("common:username")}
        value={player?.name || ""}
        onChange={inputHandler}
      />

      <OutlinedButton
        className="tab-form__button"
        disabled={!player?.name}
        onClick={startSingleGame}
      >
        {i18n.t("common:start")}
      </OutlinedButton>
    </div>
  );
};
