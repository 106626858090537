import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import "./lang-picker.scss";

import locales from "../../../config/locales";

import { ReactComponent as Arrow } from "../../../assets/svg/chevrone.svg";
import { useLocation, useNavigate } from "react-router-dom";

export const LangPicker = ({ theme = "light", color = "purple" }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpened, setIsOpened] = useState(false);
  const [language, setLanguage] = useState(
    locales.find((locale) => locale.code === i18n.language)
  );

  useEffect(() => {
    setLanguage(locales.find((locale) => locale.code === i18n.language));
  }, [i18n.language]);

  const filteredLocales = locales.filter(
    (locale) => locale.code !== language.code
  );

  const toggleIsOpened = () => {
    setIsOpened((value) => !value);
  };
  const changeLanguage = (locale) => {
    navigate(location.pathname.replace(i18n.language, locale.code));
    navigate(0);
    setLanguage(locale);
    setIsOpened(false);
  };
  return (
    <div
      className={classNames(
        "lang-picker",
        { "lang-picker--opened": isOpened },
        `lang-picker--theme--${theme}`,
        `lang-picker--color--${color}`
      )}
    >
      <button className="lang-picker__trigger" onClick={toggleIsOpened}>
        <span className="lang-picker__font lang-picker__font--trigger font--title">
          {language.title}
        </span>

        <Arrow
          className={classNames("lang-picker__icon", {
            "lang-picker__icon--active": isOpened,
          })}
        />
      </button>

      {isOpened && (
        <div className="lang-picker__options">
          {filteredLocales.map((locale) => (
            <button
              className="lang-picker__option"
              key={locale.code}
              onClick={() => changeLanguage(locale)}
            >
              <span className="lang-picker__font lang-picker__font--option font--title">
                {locale.title}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
