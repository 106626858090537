import React, {useContext} from "react";
import classNames from "classnames";

import "./reload-button.scss";
import {LangContext} from "../../App.js";

function ReloadButton({ className = "" }) {
  const i18n = useContext(LangContext);
  const restartGame = () => {
    window.location.reload();
  };

  return (
    <button
      className={classNames(className, "reload-button")}
      onClick={restartGame}
    >
      <span className="reload-button__font reload-button__font--button font--text">
        {i18n.t("common:restart")}
      </span>
    </button>
  );
}

export default ReloadButton;
