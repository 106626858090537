import React, { useContext, useEffect, useMemo, useState } from "react";

import { Avatar } from "antd";
import PropTypes from "prop-types";

import "./played-numbers-header.scss";
import { DEBOUNCED_TIME } from "../../constants";
import { Box } from "../../global_styles";
import { LangContext } from "../../App";
import classNames from "classnames";

export const PlayedNumbersHeader = ({ className, usedNumbers }) => {
  const [numbers, setNumbers] = useState([]);
  const i18n = useContext(LangContext);

  useEffect(() => {
    setTimeout(() => {
      setNumbers(usedNumbers);
    }, DEBOUNCED_TIME);
  }, [usedNumbers]);

  return (
    <div className={classNames(className, "played-numbers-header")}>
      <div className="played-numbers-header__title">
        <span className="played-numbers-header__font played-numbers-header__font--title font--text">
          {i18n.t("common:playedNumbers")}
        </span>
      </div>

      <div className="played-numbers-header__numbers">
        {numbers.map((number) => (
          <div key={number} className="played-numbers-header__number">
            <span className="played-numbers-header__font played-numbers-header__font--number font--text">
              {number}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

PlayedNumbersHeader.propTypes = {
  usedNumbers: PropTypes.array,
};
