import React from "react";

import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { StartPage, MainPage, Page404, WelcomePage } from "./pages";
import { CurrentGameProvider } from "./providers";

import "./Fonts/KyivTypeSans-Medium.ttf";
import "./Fonts/KyivTypeSans-Regular.ttf";
import "./Fonts/MAK-bold.otf";
import "./index.css";
import "./assets/scss/app.scss";

export const LangContext = React.createContext("");

function App() {
  const { i18n } = useTranslation(["translation", "common", "questions"]);
  return (
    <LangContext.Provider value={i18n}>
      <CurrentGameProvider>
        <Router>
          <Routes>
            <Route path="/:locale" element={<WelcomePage />} />
            <Route path="/:locale/choose-type" element={<StartPage />} />
            <Route path="/:locale/game" element={<MainPage />} />
            <Route path="/:locale/*" element={<Page404 />} />
            <Route path="/*" element={<Navigate to="/en" replace />} />
          </Routes>
        </Router>
      </CurrentGameProvider>
    </LangContext.Provider>
  );
}

export default App;
