import React, { useState, useContext } from "react";

import { Button, Input, message } from "antd";
import { useNavigate } from "react-router-dom";

import "../tab-form.scss";

import { CurrentGameContext } from "../../../providers/current_game";
import { createUser, generateMultiplayer } from "../../../utils";
import { LangContext } from "../../../App";
import OutlinedButton from "../../ui/OutlinedButton";
import classNames from "classnames";

export const MultiplayerTab = ({ className }) => {
  const { currentGame, updateCurrentGame } = useContext(CurrentGameContext);
  const navigate = useNavigate();
  const [players, setPlayers] = useState([createUser(""), createUser("")]);
  const i18n = useContext(LangContext);

  const inputHandler = (e) => {
    const updPlayers = players.map((player) => {
      if (player.id === +e.target.name) {
        player.name = e.target.value;
        return player;
      } else {
        return player;
      }
    });
    setPlayers(updPlayers);
  };

  const addPlayer = () => {
    if (players.length < 8) {
      setPlayers((prev) => [...prev, createUser("")]);
      if (players?.length === 7) {
        message.error(i18n.t("common:maxPlayers"));
      }
    }
  };

  const checkPlayersLength = () => {
    return !!(players?.length === 12);
  };

  const removePlayer = (id) => {
    const updPlayers = players.filter((player) => player.id !== id);
    setPlayers(updPlayers);
    if (updPlayers.length <= 2) {
      message.error(i18n.t("common:minPlayers"));
    }
  };

  const checkNames = () => {
    return !!(
      players.every((player) => player?.name.trim() !== "") &&
      players.length >= 2
    );
  };

  const startMultiplayerGame = () => {
    const names = players.map((player) => player.name);
    const multiplayer = generateMultiplayer(names);
    multiplayer.map((player) => {
      player.wrongAnswers = 0;
      player.status = "active";
      return player;
    });
    currentGame.players = [...multiplayer];
    updateCurrentGame(currentGame);
    localStorage.setItem("game", JSON.stringify(currentGame));
    navigate(`/${i18n.language}/game`);
  };

  return (
    <div className={classNames(className, "tab-form")}>
      {players.map((player, i) => (
        <div className="tab-form__row" key={i}>
          <input
            name={player.id}
            className="tab-form__input"
            value={player.name || ""}
            placeholder={`${i18n.t("common:username")} ${i + 1}`}
            onChange={inputHandler}
          />

          <button
            className="tab-form__cross-button"
            onClick={() => removePlayer(player.id)}
          >
            <img
              className="tab-form__icon"
              src="/images/cross.svg"
              alt="icon"
            />
          </button>
        </div>
      ))}

      <div className="tab-form__row">
        <button
          className="tab-form__add-button"
          onClick={addPlayer}
          disabled={checkPlayersLength()}
        >
          <span className="tab-form__add">
            <span className="tab-form__font font--text">
              {i18n.t("common:addPlayer")}
            </span>
          </span>

          <img className="tab-form__icon" src="/images/plus.svg" alt="icon" />
        </button>
      </div>

      <OutlinedButton
        className="tab-form__button"
        disabled={!checkNames()}
        onClick={startMultiplayerGame}
      >
        {i18n.t("common:start")}
      </OutlinedButton>
    </div>
  );
};
