import styled from "styled-components";

export const Footer = styled.div`
  margin-bottom: ${(props) => props.mb};
  min-height: 150px;
  width: 100%;
  max-width: 1500px;
  background: transparent;
  transition: all 1.5s ease-out;
  ::-webkit-scrollbar {
    display: none;
  }
`;
