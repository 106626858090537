import React from "react";

export const VideoFrame = ({ className }) => {
  return (
    <iframe
      width="100%"
      height="85%"
      className={className}
      src="https://www.youtube.com/embed/ggZDYCKmRro"
      title="YouTube video player"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      frameBorder="0"
      style={{ borderRadius: "1rem" }}
    ></iframe>
  );
};
