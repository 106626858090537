import React, { useEffect } from "react";

import PropTypes from "prop-types";

import { Footer } from "./style";
import { Box } from "../../global_styles";
import { PlayerCard } from "../player_card";

export const CardsArea = ({ players, toggle, setToggle, type, actualNumber }) => {
  useEffect(() => {
    setTimeout(() => {
      setToggle(false);
    }, 2500);
  }, []);

  const sortedPlayers = () => {
    if (type === "multiplayer") {
      const activePlayers = players.filter((p) => p.status === "active");
      const inactivePlayers = players.filter((p) => p.status === "inactive");

      return [...activePlayers, ...inactivePlayers];
    } else {
      return players;
    }
  };

  return (
    <Footer mb={toggle ? "-156px" : "0"}>
      <Box
        style={{
          display: "flex",
          width: "100%",
          margin: "0 auto",
          overflowY: "auto",
        }}
      >
        {sortedPlayers().map((player, i) => (
          <Box
            onClick={() => setToggle((prev) => !prev)}
            key={player.id}
            m="4px"
          >
            <PlayerCard player={player} actualNumber={actualNumber} />
          </Box>
        ))}
      </Box>
    </Footer>
  );
};

CardsArea.propTypes = {
  players: PropTypes.array,
  toggle: PropTypes.bool,
  setToggle: PropTypes.func.isRequired,
  type: PropTypes.string,
  actualNumber: PropTypes.number,
};
