import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import locales from "./config/locales";

const Languages = locales.map((locale) => locale.code);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: locales[0].code,
    debug: true,
    whitelist: Languages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
